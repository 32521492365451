import { call, put, takeEvery, take, select } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { getProvider } from "../../providers";
/* Selector */
import { selectSignsByKey } from "./selectors";
// Redux Actions
import { ETH_PLAIN_SIGN_PROCESSED } from "@ensuro/ethereum-store/src/store/ethereum/actionTypes";
import { SET_PROVIDER, SET_USER, SET_CHAIN_ID } from "./actionTypes";

function providerListenerChannel(provider) {
  return eventChannel((emitter) => {
    /*if (provider.removeAllListeners)
      provider.removeAllListeners();*/
    provider.on("accountsChanged", (evtData) => {
      // Track Wallet can't emit this event, so it's always true
      emitter({ type: SET_USER, payload: evtData[0], canTransact: true });
    });

    provider.on("chainChanged", (evtData) => {
      emitter({ type: SET_CHAIN_ID, payload: parseInt(evtData, 16) });
    });

    provider.on("disconnect", () => {
      emitter({ type: SET_PROVIDER, payload: null });
      emitter(END);
    });
    return () => {};
  });
}

export function* listenToEvents({ payload }) {
  if (payload == null) return;
  const channel = yield call(providerListenerChannel, getProvider(payload));
  try {
    while (true) {
      let action = yield take(channel);
      yield put(action);
    }
  } finally {
  }
}

export function* storeUser({ key, userAddress, signature, message }) {
  const state = yield select((state) => state.UserReducer);
  const sign = selectSignsByKey(state, key, userAddress);
  if (sign) {
    const args = sign.args;
    try {
      yield put({
        type: "API_CALL",
        apiName: "storeKYC",
        args: [userAddress],
        method: "POST",
        data: {
          address: userAddress,
          message: message,
          signature: signature,
          email: args.email,
          data: { country: args.country, occupation: args.occupation, whitelist: args.whitelist },
        },
      });
    } catch (error) {}
  }
}

export function* userSaga() {
  yield takeEvery(SET_PROVIDER, listenToEvents);
  yield takeEvery(ETH_PLAIN_SIGN_PROCESSED, storeUser);
}

export default userSaga;
