import { init } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import coinbaseModule from "@web3-onboard/coinbase";
import walletConnectModule from "@web3-onboard/walletconnect";
import trackWallet from "./TrackWallet/index.js";
import ensuroLogo from "../../assets/images/logo-dark.png";
import icon from "../../assets/images/ensuro-icon.png";
import { chain, walletConnect as wc } from "../../config";

const injected = injectedModule();
const coinbase = coinbaseModule();
const myPlugin = trackWallet();

const walletConnect = walletConnectModule({
  version: 2,
  handleUri: (uri) => console.log(uri),
  requiredChains: [chain.id],
  projectId: wc.projectId,
});

export const initWeb3Onboard = init({
  wallets: [injected, coinbase, walletConnect, myPlugin],
  chains: [{ id: chain.hexId, token: chain.token, label: chain.name, rpcUrl: chain.rpc }],
  appMetadata: {
    name: "Ensuro v2",
    icon: icon,
    logo: ensuroLogo,
    description: "Ensuro v2",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      { name: "MetaMask", url: "https://metamask.io" },
    ],
  },
  connect: { autoConnectLastWallet: true },
  accountCenter: { desktop: { enabled: false }, mobile: { enabled: false } },
});
