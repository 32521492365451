import React, { useEffect } from "react";
/* Components */
import Risks from "./Risks";
/* Selectors */
import { selectAPICall } from "@ensuro/api-calls-store/src/store/api/selectors";
import { selectSetting } from "../../../store/settings/selectors";
/* Redux */
import { connect, useDispatch } from "react-redux";
import * as actionTypes from "../../../store/risks/actionTypes";

const RisksTable = ({ risks, mode, state }) => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actionTypes.SET_SELECTED_RISK, address: "" });
  }, [dispatch]);

  const getActivePolicies = function (rm) {
    return selectAPICall(state.APIReducer, "activePolicies", [rm.address]) || -1;
  };
  const sortedRisks = [...risks].sort((r1, r2) => getActivePolicies(r2) - getActivePolicies(r1));

  return <Risks risks={sortedRisks} state={state.RisksReducer} mode={mode} />;
};

const mapStateToProps = (state) => {
  const risks = state.RisksReducer.risks;
  const mode = selectSetting(state.SettingsReducer, "VIEW_MODE");
  return { risks, state, mode };
};

export default connect(mapStateToProps)(RisksTable);
