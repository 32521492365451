import { useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import MiniWidget from "../../CommonForBoth/MiniWidget";
import LineMinichart from "../../Charts/LineMinichart";
import { Container, Row, Col } from "react-bootstrap";
import BarMinichart from "../../Charts/BarMinichart";
import ReactMarkdown from "react-markdown";
import { Card } from "semantic-ui-react";
/* Helpers and Selectors */
import { addRemoveApiSub } from "@ensuro/api-calls-store/src/utils/helpers/store_helper";
import { selectAPICallMultiple } from "@ensuro/api-calls-store/src/store/api/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { minicharts } from "../../../config";
import colors from "../../../colors";

const makeAPICalls = (rmAddress) => {
  return [
    { apiName: "activePolicies", args: [rmAddress] },
    { apiName: "gwp", args: [rmAddress] },
    { apiName: "scr", args: [rmAddress] },
    { apiName: "maturedSurplus", args: [rmAddress] },
  ];
};

const minichartAPICalls = (rmAddress) => {
  return [
    { apiName: "activePoliciesHistory", args: [rmAddress, minicharts.daysFrom] },
    { apiName: "gwpHistory", args: [rmAddress, minicharts.daysFrom] },
    { apiName: "scrHistory", args: [rmAddress, minicharts.daysFrom] },
    { apiName: "maturedSurplusHistory", args: [rmAddress, minicharts.daysFrom] },
  ];
};

const RiskTabContent = ({ t, rm, actPolicies, gwp, scr, mSurpus, policiesHist, gwpHist, scrHist, mSurpusHist }) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/risks/${rm.address}`);
  };

  useEffect(() => {
    return addRemoveApiSub(
      dispatch,
      `riskTab-${rm.address}`,
      makeAPICalls(rm.address).concat(minichartAPICalls(rm.address))
    );
  }, [dispatch, rm.address]);

  return (
    <Container>
      <Row>
        <Col md={7}>
          <Row>
            <MiniWidget
              data={{
                title: t("Active Policies"),
                label: t("Active Policies"),
                value: actPolicies,
                options: { notation: "standard" },
              }}
              Component={LineMinichart}
              graph={policiesHist}
              dataKey="value"
              color={colors.primaryLight}
              address={rm.address}
              apiCall={"activePoliciesHistory"}
              xl={6}
            />
            <MiniWidget
              data={{ title: t("GWP"), value: gwp, label: "GWP [$]", options: { notation: "compact" }, prefix: "$" }}
              Component={BarMinichart}
              graph={gwpHist}
              dataKey="gwp"
              color={colors.skyBlue}
              address={rm.address}
              apiCall={"gwpHistory"}
              xl={6}
            />
            <MiniWidget
              data={{ title: t("SCR"), value: scr, label: "SCR [$]", options: { notation: "compact" }, prefix: "$" }}
              Component={LineMinichart}
              graph={scrHist}
              dataKey="scr"
              color={colors.pink}
              address={rm.address}
              apiCall={"scrHistory"}
              xl={6}
            />
            <MiniWidget
              data={{
                title: t("Surplus/Deficit"),
                label: "Surplus [$]",
                value: mSurpus,
                options: { notation: "compact" },
                prefix: "$",
              }}
              Component={LineMinichart}
              graph={mSurpusHist}
              dataKey="matured_surplus"
              color={colors.green}
              address={rm.address}
              apiCall={"maturedSurplusHistory"}
              xl={6}
            />
            <Col md={12} xs={12}>
              <Card className="metric-card">
                <div className="centered font-size-21 with-pointer">
                  <div onClick={handleClick}>
                    {t("More details")} <i className="uil uil-arrow-right"></i>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={5}>
          <Card className="description-card">
            <div className="font-size-24 weight-600 with-pointer" onClick={handleClick}>
              <span className="logo-sm">
                <img src={rm.logo} alt="" height="40" />
              </span>{" "}
              {rm.name}
            </div>
            <br />
            <ReactMarkdown>{rm.description}</ReactMarkdown>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

RiskTabContent.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const rm = ownProps.rm;
  const [actPolicies, gwp, scr, mSurpus] = selectAPICallMultiple(state.APIReducer, makeAPICalls(rm.address));
  const [policiesHist, gwpHist, scrHist, mSurpusHist] = selectAPICallMultiple(
    state.APIReducer,
    minichartAPICalls(rm.address)
  );

  return { rm, actPolicies, gwp, scr, mSurpus, policiesHist, gwpHist, scrHist, mSurpusHist };
};

export default connect(mapStateToProps)(withTranslation()(RiskTabContent));
