import { useState } from "react";
import PropTypes from "prop-types";
import WhitelistModal from "./WhitelistModal";
import SignInModal from "./SignInModal";
import { withTranslation } from "react-i18next";

const KYCFormModal = ({ t, show, onHide, needsWhitelist }) => {
  const [showSignInModal, setShowSignInModal] = useState(false);

  const handleContinue = () => {
    setShowSignInModal(true);
  };

  return (
    <>
      {needsWhitelist ? (
        <WhitelistModal show={show} onHide={onHide} onContinue={handleContinue} />
      ) : (
        <SignInModal show={show} onHide={onHide} />
      )}
      <SignInModal show={showSignInModal} onHide={() => setShowSignInModal(false)} />
    </>
  );
};

KYCFormModal.propTypes = {
  t: PropTypes.any,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  needsWhitelist: PropTypes.bool.isRequired,
};

export default withTranslation()(KYCFormModal);
