import React from "react";
import { Row, Col } from "react-bootstrap";
import BlurredNumber from "./Blurred/BlurredNumber";
import BlurredAddress from "./Blurred/BlurredAddress";
import BlurredComparison from "./Blurred/BlurredComparison";
import BlurredLinkedString from "./Blurred/BlurredLinkedString";

const Parameter = ({ name, value, suffix, percentage, options, renderType }) => {
  let renderComponent;

  switch (renderType) {
    case "address":
      renderComponent = <BlurredAddress str={value.state} address={true} />;
      break;
    case "comparison":
      renderComponent = <BlurredComparison current={value.current} limit={value.limit} options={options} />;
      break;
    case "link":
      renderComponent = <BlurredLinkedString value={value.state} linkedTo={value.link.linkedTo} str={value.link.str} />;
      break;
    case "dualMetric":
      renderComponent = (
        <div>
          <p>
            <span>{options.label1}</span>
            <BlurredNumber number={value.value1} suffix={suffix} percentage={percentage} options={options} />
          </p>
          <p>
            <span>{options.label2}</span>
            <BlurredNumber number={value.value2} suffix={suffix} percentage={percentage} options={options} />
          </p>
        </div>
      );
      break;
    default:
      renderComponent = <BlurredNumber number={value} suffix={suffix} percentage={percentage} options={options} />;
      break;
  }

  return (
    <Row>
      <Col md={5} xs={6}>
        <p>
          <strong>{name}</strong>
        </p>
      </Col>
      <Col md={7} xs={6} className="params-value params-col">
        {renderComponent}
      </Col>
    </Row>
  );
};

export default Parameter;
