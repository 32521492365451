import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import { Modal, Form, InputGroup } from "react-bootstrap";
import WrongNetworkAlert from "./WrongNetworkAlert";
import TxButton from "../CommonForBoth/TxButton";
/* Helpers */
import { strToDec, strToDecZ, strToBN } from "../../helpers/number_format_helper";
import { isTransactionValid } from "../../helpers/store_helper";
import { calcOnLoad } from "../../helpers/ensuro_helper";
/* Stores */
import { selectEthCallMultiple, selectLastTransact } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { selectCurrent } from "../../store/eTokens/selectors";
import { selectChainId } from "../../store/user/selectors";
/* i18n and Config */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { chain, currency } from "../../config";
import "./style.scss";

const { ethers } = require("ethers");

const makeEthCalls = function (userAddress, poolAddress, etkAddress) {
  return [
    { address: currency.address, abi: "ERC20", method: "allowance", args: [userAddress, poolAddress] },
    { address: etkAddress, abi: "EToken", method: "minUtilizationRate", args: [] },
    { address: etkAddress, abi: "EToken", method: "totalSupply", args: [] },
    { address: etkAddress, abi: "EToken", method: "scr", args: [] },
  ];
};

const DepositModal = ({
  t,
  lastTransaction,
  show,
  onHide,
  balance,
  user,
  userAddress,
  allowance,
  minUtilizationRate,
  totalSupply,
  scr,
  poolAddress,
  etkAddress,
  chainId,
}) => {
  let dispatch = useDispatch();
  const [userAmount, setUserAmount] = useState("");
  const [infititeApproval, setInfititeApproval] = useState(false);
  const ready = allowance.state === "LOADED" && balance.state === "LOADED" && chainId === chain.id;
  const isOversold = calcOnLoad((ts, scr, minUR) => scr / ts < minUR, totalSupply, scr, minUtilizationRate);

  useEffect(() => {
    if (userAddress && etkAddress && poolAddress !== null) {
      dispatch({
        type: "ETH_ADD_SUBSCRIPTION",
        key: "depositModal",
        componentEthCalls: makeEthCalls(userAddress, poolAddress, etkAddress),
      });
    }
    return () => {
      dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: "depositModal" });
    };
  }, [dispatch, etkAddress, poolAddress, userAddress]);

  const invalidBalance = () => {
    return !balance.value || (strToDec(userAmount) && strToDec(userAmount).gt(balance.value));
  };

  const requiresApprove = () => {
    let ua = strToDec(userAmount);
    return (ua === undefined || !allowance.value || allowance.value.lt(ua)) && userAmount;
  };

  const dispatchTransfer = () => {
    if (!isTransactionValid(lastTransaction)) return;
    if (requiresApprove()) {
      let amount = infititeApproval ? ethers.MaxInt256 : strToBN(userAmount);
      dispatch({
        type: "ETH_TRANSACT",
        address: currency.address,
        abi: "ERC20",
        method: "approve",
        args: [poolAddress, amount],
      });
    } else {
      dispatch({
        type: "ETH_TRANSACT",
        address: poolAddress,
        abi: "PolicyPool",
        method: "deposit",
        args: [etkAddress, strToBN(userAmount)],
      });
    }
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Deposit ", { currency })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {chainId !== chain.id && <WrongNetworkAlert />}
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{t("Amount")}</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="0.00"
                id="amount"
                isInvalid={userAmount !== "" && (!strToDecZ(userAmount) || invalidBalance() || isOversold?.value)}
                value={userAmount}
                onChange={(e) => setUserAmount(e.target.value || "")}
              />
              <InputGroup.Text>{currency.name}</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {!strToDecZ(userAmount) ? (
                  t("Please provide a valid amount.")
                ) : invalidBalance() ? (
                  <span>{t("Your balance is not enough.")}</span>
                ) : (
                  isOversold?.value && <span>{t("Utilization Rate is under Min Utilization Rate.")}</span>
                )}
              </Form.Control.Feedback>
            </InputGroup>
            <p className="align-end">
              {t("YourCurrencyBalance", { currency })}: {balance.value * 1}
              <strong onClick={() => setUserAmount(balance.value.toString())} className="btn-text">
                {t("MAX")}
              </strong>
            </p>
          </Form.Group>

          {requiresApprove() && (
            <>
              <p>{t("Please approve before depositing")}</p>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={infititeApproval}
                onChange={() => setInfititeApproval(!infititeApproval)}
                label={t("Infinite Approval")}
              />
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <em style={{ color: "red" }}>{lastTransaction ? lastTransaction.error : ""}</em>
        <TxButton
          chainId={chainId}
          disabled={
            !ready || !strToDecZ(userAmount) || invalidBalance() || (user && !user.canTransact) || isOversold?.value
          }
          onClick={dispatchTransfer}
          text={requiresApprove() ? t("Approve") : t("Deposit ", { currency })}
        />
      </Modal.Footer>
      {user && !user.canTransact && <p className="read-only">{t("ReadOnlyModeText")}</p>}
    </Modal>
  );
};

DepositModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const user = state.UserReducer;
  const userAddress = user.address;
  const etk = selectCurrent(state.ETokensReducer);
  const etkAddress = etk.address;
  const etkSymbol = etk.symbol;
  const url = new URL(etk.pool);
  const poolAddress = url.pathname.split("/")[3];
  const lastTransaction = selectLastTransact(state.EthereumReducer);
  const chainId = selectChainId(state.UserReducer);

  const [allowance, minUtilizationRate, totalSupply, scr] = !userAddress
    ? [{}, {}, {}, {}]
    : selectEthCallMultiple(state.EthereumReducer, makeEthCalls(userAddress, poolAddress, etkAddress));

  return {
    lastTransaction,
    user,
    userAddress,
    allowance,
    minUtilizationRate,
    totalSupply,
    scr,
    poolAddress,
    etkAddress,
    etkSymbol,
    chainId,
  };
};

export default connect(mapStateToProps)(withTranslation()(DepositModal));
