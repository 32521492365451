import React, { useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import BlurredNumber from "../../CommonForBoth/Blurred/BlurredNumber";
import { Row, Col, Form } from "react-bootstrap";
import Badges from "../../CommonForBoth/Badges";
import { Card } from "semantic-ui-react";
/* Store */
import { selectEthCallMultiple } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { selectAPICallMultiple } from "@ensuro/api-calls-store/src/store/api/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
/* Helpers */
import { addRemoveSub } from "../../../helpers/store_helper";
import { calcOnLoad } from "../../../helpers/ensuro_helper";
import { apy, images } from "../../../config";
import "./../style.scss";

const makeEthCalls = function (etkAddress) {
  return [
    { address: etkAddress, abi: "EToken", method: "totalSupply", args: [] },
    { address: etkAddress, abi: "EToken", method: "scr", args: [] },
    { address: etkAddress, abi: "EToken", method: "minUtilizationRate", args: [] },
  ];
};

const makeAPICalls = (etkAddress) => {
  return [{ apiName: "apy", args: [etkAddress, apy.range] }];
};

const SrETokenCard = ({ t, eToken, totalSupply, scr, apy, minUR, poolBadges, onCheck, checked }) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let tags = eToken.custom_data?.tags || [];

  const handleCardClick = (address) => {
    navigate(`/eTokens/${address}`);
  };

  const isOversold = calcOnLoad((ts, scr, minUR) => scr / ts < minUR, totalSupply, scr, minUR);
  if (isOversold.value === true) {
    tags = [...tags, "oversold"];
  }

  useEffect(() => {
    return addRemoveSub(
      dispatch,
      `srEtkCard-${eToken.address}`,
      makeAPICalls(eToken.address),
      makeEthCalls(eToken.address)
    );
  }, [dispatch, eToken.address]);

  const handleCheck = () => {
    onCheck(eToken);
  };

  return (
    <Col md={5} xs={11}>
      <Card fluid className="sr-etk-card sr-hover">
        <Form.Check type="checkbox" className="expand-btn float outside" checked={checked} onChange={handleCheck} />
        <div style={{ cursor: "pointer" }} onClick={() => handleCardClick(eToken.address)}>
          <Row>
            <Col className="sr-etk-name">
              <span className="logo-sm">
                <img src={eToken.logo || images.defaultEtkLogo} alt="" height="35" />
              </span>{" "}
              {eToken.name}
            </Col>
          </Row>
          <Row className="table-header">
            <Col className="align-self-center">
              <Badges tags={tags} badgesData={poolBadges} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <Card className="card-inside sr-etk-number" style={{ color: "black" }}>
                <BlurredNumber
                  number={apy}
                  suffix={"%"}
                  percentage={true}
                  options={{ maximumFractionDigits: 2, notation: "standard" }}
                />
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="card-inside sr-etk-number" style={{ color: "black" }}>
                <BlurredNumber number={totalSupply} options={{ maximumFractionDigits: 2, notation: "compact" }} />
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="card-inside sr-etk-number" style={{ color: "black" }}>
                <BlurredNumber
                  number={calcOnLoad((a, b) => a / b, scr, totalSupply)}
                  options={{ maximumFractionDigits: 2, notation: "standard" }}
                  percentage={true}
                  suffix={"%"}
                />
              </Card>
            </Col>
            <Col xs={4} className="text-align-center">
              <strong>{t("APY")}</strong>
            </Col>
            <Col xs={4} className="text-align-center">
              <strong>{t("Liquidity")}</strong>
            </Col>
            <Col xs={4} className="text-align-center">
              <strong>{t("Utilization Rate")}</strong>
            </Col>
          </Row>
        </div>
        <br />
      </Card>
    </Col>
  );
};

SrETokenCard.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const etkAddress = ownProps.eToken.address;
  const [totalSupply, scr, minUR] = selectEthCallMultiple(state.EthereumReducer, makeEthCalls(etkAddress));
  const [apy] = selectAPICallMultiple(state.APIReducer, makeAPICalls(etkAddress));
  return { totalSupply, scr, apy, minUR };
};

export default connect(mapStateToProps)(withTranslation()(SrETokenCard));
