import React, { useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import MultiLineChart from "../Charts/MultiLineChart";
import MultiBarChart from "../Charts/MultiBarChart";
import { Row, Col } from "react-bootstrap";
/* Helpers and Selectors */
import { addRemoveApiSub } from "@ensuro/api-calls-store/src/utils/helpers/store_helper";
import { selectAPICallMultiple } from "@ensuro/api-calls-store/src/store/api/selectors";
import moment from "moment";
/* i18n */
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

const CashflowCharts = ({
  t,
  address,
  daysFrom,
  activePremiums,
  maturedSurplusHistory,
  cashflow,
  wonpremiums,
  chartCalls,
}) => {
  let dispatch = useDispatch();

  useEffect(() => {
    const key = `cashflowChart-${address}-${daysFrom}`;
    const calls = chartCalls(address, daysFrom);
    return addRemoveApiSub(dispatch, key, calls);
  }, [address, chartCalls, daysFrom, dispatch]);

  function PremiumsSurplusTooltip({ payload, label, active }) {
    if (active && payload && payload?.length > 0) {
      return (
        <div className="custom-tooltip">
          <b>{moment(label).format("ll")} </b>
          <p className="active-premiums">{`Active Premiums: $${payload[0].value.toFixed(2)}`}</p>
          <p className="surplus">{`Surplus: $${payload[1].value.toFixed(2)}`}</p>
          {payload.length > 2 && (
            <p className="hallmark">
              {payload[2]?.payload?.realValue > 0 ? "Deposit:" : "Withdraw:"}{" "}
              {`$${payload[2]?.payload?.realValue.toFixed(2)}`}
            </p>
          )}
        </div>
      );
    }
    return null;
  }

  function CashflowTooltip({ payload, label, active }) {
    if (active && payload && payload?.length > 0) {
      return (
        <div className="custom-tooltip">
          <b>{moment(label).format("ll")} </b>
          <p className="premium">{`Premium: $${payload[0].value.toFixed(2)}`}</p>
          <p className="payout">{`Payout: $${payload[1].value.toFixed(2)}`}</p>
          {payload[2].value > 0 ? (
            <p className="positive-total">
              {t("Total")}: ${payload[2].value.toFixed(2)}
            </p>
          ) : (
            <p className="negative-total">
              {t("Total")}: ${payload[2].value.toFixed(2)}
            </p>
          )}
        </div>
      );
    }
    return null;
  }

  return (
    <>
      <Row>
        <Col md={12} align="center">
          <MultiLineChart
            xKey={"date"}
            xLabel={t("Date")}
            leftData={activePremiums}
            leftKey={"active_premiums"}
            leftLabel={t("Active Premiums [$]")}
            rightData={maturedSurplusHistory}
            rightKey={"matured_surplus"}
            rightLabel={t("Surplus [$]")}
            scatter={wonpremiums}
            scatterKey={"wonpremiums"}
            CustomTooltip={PremiumsSurplusTooltip}
          />
        </Col>
      </Row>
      <br />
      {cashflow && cashflow.value?.length > 0 && (
        <Row style={{ marginBottom: "2rem" }}>
          <Col md={12} align="center">
            <MultiBarChart
              data={cashflow}
              xKey={"date"}
              xLabel={t("Date")}
              yLabel={t("Cashflow [$]")}
              yKeys={["value.premium", "value.payout", "value.total"]}
              CustomTooltip={CashflowTooltip}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

CashflowCharts.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const address = ownProps.address;
  const daysFrom = ownProps.daysFrom;
  const chartCalls = ownProps.apiCalls;

  const [activePremiums, maturedSurplusHistory, cashflow, wonpremiums] = selectAPICallMultiple(
    state.APIReducer,
    chartCalls(address, daysFrom)
  );

  return { address, activePremiums, maturedSurplusHistory, cashflow, wonpremiums, chartCalls };
};

export default connect(mapStateToProps)(withTranslation()(CashflowCharts));
