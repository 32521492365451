import React from "react";
import PropTypes from "prop-types";
/* Components */
import { Modal, Button } from "react-bootstrap";
/* i18n and Config */
import { withTranslation } from "react-i18next";
import "./style.scss";

const WhitelistModal = ({ t, show, onHide, user, chainId }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title-centered">{t("Welcome to Ensuro")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t(
            "This pool requires KYC (Know Your Customer) verification to confirm the user's identity, you need to complete the whitelisting process. Please use the link below to fill out the necessary form and proceed with your application."
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => window.open("https://forms.gle/e87UAL13zaRN1kM8A", "_blank", "noopener,noreferrer")}>
          {t("KYC Form")}
        </Button>{" "}
        <Button onClick={() => window.open("https://forms.gle/uaYFCvLR2BNEHr8v5", "_blank", "noopener,noreferrer")}>
          {t("KYB Form")}
        </Button>{" "}
      </Modal.Footer>
    </Modal>
  );
};

WhitelistModal.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(WhitelistModal);
