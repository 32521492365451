// PARowDesktop.js
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import BlurredNumber from "../../CommonForBoth/Blurred/BlurredNumber";
import CustomTooltip from "../../CommonForBoth/CustomTooltip";
import Badges from "../../CommonForBoth/Badges";
import IconButton from "../../CommonForBoth/IconButton";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { Card } from "semantic-ui-react";
import { selectEthCallMultiple } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { selectAPICallMultiple } from "@ensuro/api-calls-store/src/store/api/selectors";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import defaultRMLogo from "../../../assets/images/risks-symbol.png";
import defaultPALogo from "../../../assets/images/PA_color.svg";
import { addRemoveSub } from "../../../helpers/store_helper";
import "./../style.scss";

const makeEthCalls = function (paAddress) {
  return [{ address: paAddress, abi: "PremiumsAccount", method: "activePurePremiums", args: [] }];
};

const makeAPICalls = (paAddress) => {
  return [
    { apiName: "paActivePolicies", args: [paAddress] },
    { apiName: "rmByPremiumsAccount", args: [paAddress] },
    { apiName: "paMaturedSurplus", args: [paAddress] },
  ];
};

const PARowDesktop = ({ t, pa, idx, activePurePremiums, activePolicies, rms, maturedSurplus, paBadges }) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let tags = pa.custom_data?.tags || [];

  const handleNavigation = (url) => (evt) => {
    evt.stopPropagation();
    navigate(url);
  };

  useEffect(() => {
    return addRemoveSub(dispatch, `paDesktop-${pa.address}`, makeAPICalls(pa.address), makeEthCalls(pa.address));
  }, [dispatch, pa.address]);

  return (
    <Container className="desktop no-margin">
      <Card
        className="card-row card-hover primary-colored"
        onClick={handleNavigation(`/portfolios/${pa.address}`)}
        key={idx}
      >
        <Container className="no-margin">
          <Row>
            <Col md={3} className="font-size-16 name-with-logo margin-auto">
              <span className="logo-sm with-20-padding">
                <img src={pa.logo || defaultPALogo} alt="" height="30" />
              </span>{" "}
              {pa.name} <Badges tags={tags} badgesData={paBadges || []} />
            </Col>
            <Col md={1}>
              <Card className="card-inside primary-colored">
                <BlurredNumber number={activePolicies} options={{ notation: "standard" }} />
              </Card>
            </Col>
            <Col md={3}>
              <Card className="card-inside primary-colored">
                <BlurredNumber
                  number={activePurePremiums}
                  options={{ maximumFractionDigits: 2, notation: "compact" }}
                />
              </Card>
            </Col>
            <Col md={2}>
              <Card className="card-inside" style={{ color: Math.sign(maturedSurplus.value) < 0 ? "red" : "green" }}>
                <BlurredNumber number={maturedSurplus} options={{ maximumFractionDigits: 2, notation: "compact" }} />
              </Card>
            </Col>
            <Col md={3}>
              <Card className="card-inside">
                <span className="logo-sm with-20-padding">
                  {rms &&
                    rms.value &&
                    rms.value.length > 0 &&
                    rms.value.map((rm, idx) => (
                      <CustomTooltip key={idx} text={rm.name} placement="top">
                        <Badge bg="transparent">
                          <IconButton
                            onClick={handleNavigation(`/risks/${rm.address}`)}
                            src={rm.logo || defaultRMLogo}
                            alt={rm.name}
                            height={30}
                            imgClassName="logo-sm"
                          />
                        </Badge>
                      </CustomTooltip>
                    ))}
                </span>
              </Card>
            </Col>
          </Row>
        </Container>
      </Card>
    </Container>
  );
};

PARowDesktop.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => {
  const paAddress = ownProps.pa.address;
  const [activePurePremiums] = selectEthCallMultiple(state.EthereumReducer, makeEthCalls(paAddress));
  const [activePolicies, rms, maturedSurplus] = selectAPICallMultiple(state.APIReducer, makeAPICalls(paAddress));
  return { activePurePremiums, activePolicies, rms, maturedSurplus };
};

export default connect(mapStateToProps)(withTranslation()(PARowDesktop));
