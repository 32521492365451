const occupations = [
  "Student",
  "Engineer",
  "Teacher",
  "Healthcare Professional",
  "Artist",
  "Business Owner",
  "Lawyer",
  "Accountant",
  "Programmer",
  "Entrepreneur",
  "Sales Representative",
  "Consultant",
  "Government Worker",
  "Retiree",
  "Finance",
  "Actuary",
  "Other",
];

export default occupations;
