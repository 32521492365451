import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import Parameter from "../CommonForBoth/Parameter";
import { calcOnLoad, etherscanLink } from "../../helpers/ensuro_helper";
import colors from "../../colors";
import "./style.scss";

import { selectEtkByAddress } from "../../store/eTokens/selectors";
import { connect } from "react-redux";

const PAParams = ({
  t,
  address,
  jrLoanLimit,
  srLoanLimit,
  jrLoan,
  srLoan,
  assetManager,
  deficitRatio,
  activePurePremiums,
  surplus,
  jrEtkAddress,
  srEtkAddress,
  jrEtk,
  srEtk,
}) => {
  return (
    <Card fluid className="params">
      <a target="_blank" className="right-aligned" rel="noopener noreferrer" href={etherscanLink(address)}>
        <p className="font-size-14">
          {t("ViewContractInBlockExplorer")} <i className="uil uil-external-link-alt" />
        </p>
      </a>
      <br />

      <Parameter
        name={t("JR Loan")}
        value={{ current: jrLoan, limit: jrLoanLimit }}
        renderType={"comparison"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      <hr style={{ width: "95%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("SR Loan")}
        value={{ current: srLoan, limit: srLoanLimit }}
        renderType={"comparison"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      <hr style={{ width: "95%", color: colors.secondary, marginTop: "0" }} />
      <Parameter name={t("Asset Manager")} value={{ state: assetManager.am, address: true }} renderType={"address"} />

      <hr style={{ width: "95%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("Max Deficit")}
        value={{
          current: calcOnLoad((a) => (a < 0 ? a * -1 : 0), surplus),
          limit: calcOnLoad((a, b) => a * b, deficitRatio, activePurePremiums),
        }}
        renderType={"comparison"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      <hr style={{ width: "95%", color: colors.secondary, marginTop: "0" }} />
      <Parameter
        name={t("Junior Pool")}
        value={{ state: jrEtkAddress, link: { linkedTo: `/etokens/${jrEtk?.address}`, str: jrEtk?.name } }}
        renderType={"link"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      <hr style={{ width: "95%", color: colors.secondary, marginTop: "0" }} />
      <Parameter
        name={t("Senior Pool")}
        value={{ state: srEtkAddress, link: { linkedTo: `/etokens/${srEtk?.address}`, str: srEtk?.name } }}
        renderType={"link"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />
      <br />
    </Card>
  );
};

PAParams.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state, { jrEtkAddress, srEtkAddress }) => {
  return {
    jrEtk: jrEtkAddress.state === "LOADED" && selectEtkByAddress(state.ETokensReducer, jrEtkAddress.value),
    srEtk: srEtkAddress.state === "LOADED" && selectEtkByAddress(state.ETokensReducer, srEtkAddress.value),
  };
};

export default connect(mapStateToProps)(withTranslation()(PAParams));
