import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const IconButton = ({ onClick, src, alt, height, imgClassName }) => (
  <span className="icon-button" onClick={onClick}>
    <img className={imgClassName} src={src} alt={alt} height={height} />
  </span>
);

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgClassName: PropTypes.string,
};

IconButton.defaultProps = {
  alt: "",
  height: 30,
};

export default IconButton;
