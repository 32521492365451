import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { NumericFormat } from "react-number-format";
import rmIcon from "../../assets/images/RM_color.svg";
import colors from "../../colors";
import CustomPieChart from "../Charts/PieChart";

const BreakdownSection = ({ data, title, selectTab, samePage = false }) => {
  return (
    <>
      {data.length > 1 && (
        <Row>
          <Col md={12}>
            <Card className="risk-card-detail-inside">
              <hr style={{ color: colors.primaryDark }} />
              <h4>{title}</h4>
            </Card>
          </Col>
          <Col md={12}>
            <Row>
              <Col md={4} sm={12}>
                {data.map((item, index) => (
                  <Card key={index} className="risk-card-detail-inside breakdown-item">
                    <div className="risk-item-container">
                      <div className="risk-item-logo">
                        <img src={rmIcon} alt="" height="35" />
                      </div>
                      <div className="risk-item-text">
                        {samePage ? (
                          <a href="#tabs" style={{ cursor: "pointer" }}>
                            <h2 className={`font-size-${24 - 2 * index}`} style={{ margin: 0 }}>
                              {item.name}
                            </h2>
                          </a>
                        ) : (
                          <Link to={item.address ? `/portfolios/${item.address}` : "/portfolios"}>
                            <h2 className={`font-size-${24 - 2 * index}`} style={{ margin: 0 }}>
                              {item.name}
                            </h2>
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className={`colored font-size-${24 - 2 * index} risk-item-value`}>
                      <NumericFormat suffix={"%"} decimalScale={2} value={item.value} displayType="text" />
                    </div>
                  </Card>
                ))}
              </Col>
              <Col md={8} sm={12}>
                <CustomPieChart data={data} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BreakdownSection;
