import React from "react";
import BlurredNumber from "./BlurredNumber";

const BlurredComparison = ({ current, limit, options }) => {
  return (
    <React.Fragment>
      <BlurredNumber number={current} options={options} /> of <BlurredNumber number={limit} options={options} />
    </React.Fragment>
  );
};

export default BlurredComparison;
