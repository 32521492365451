import { useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import { Container, Tab, Table, Tabs } from "react-bootstrap";
import Address from "../CommonForBoth/Address";
import { Card } from "semantic-ui-react";
/* Store */
import { addRemoveApiSub } from "@ensuro/api-calls-store/src/utils/helpers/store_helper";
import { selectAPICallMultiple } from "@ensuro/api-calls-store/src/store/api/selectors";
import { selectCurrent } from "../../store/eTokens/selectors";
//i18n
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
/* Helpers */
import { compactNumber } from "../../helpers/number_format_helper";
import { chain } from "../../config";
import moment from "moment";
import "./single_eToken.scss";

const makeAPICalls = (etkAddress) => {
  return [
    { apiName: "lpEvents", args: [etkAddress, "-timestamp"] },
    { apiName: "etkOwners", args: [etkAddress] },
  ];
};

const LPActivity = ({ t, etkAddress, lpEvents, etkOwners }) => {
  let dispatch = useDispatch();

  useEffect(() => {
    return addRemoveApiSub(dispatch, "lpActivity", makeAPICalls(etkAddress));
  }, [dispatch, etkAddress]);

  if (etkOwners && etkOwners.value?.length > 0) {
    let totalPercentage = etkOwners.value.reduce((acc, o) => acc + o.percentage, 0);
    let totalAmount = etkOwners.value.reduce((acc, o) => acc + o.amount, 0);
    if (totalPercentage < 100) {
      etkOwners.value.push({
        lp: "Others",
        amount: totalAmount * ((100 - totalPercentage) / 100),
        percentage: 100 - totalPercentage,
      });
    }
  }

  return (
    <>
      {((lpEvents && lpEvents.value?.length > 0) || (etkOwners && etkOwners.value?.length > 0)) && (
        <Card fluid>
          <br />
          <Container>
            <Tabs className="mb-3 tab-font activity-tabs">
              {lpEvents && lpEvents.value?.length > 0 && (
                <Tab eventKey="lp-movements" title={t("LP Movements")} className="tableFixHead ">
                  <Table striped className="whitespace-nowrap with-bkg table-rounded">
                    <thead>
                      <tr>
                        <th>{t("Owner")}</th>
                        <th>{t("Type")}</th>
                        <th>{t("Amount")}</th>
                        <th>{t("Date")}</th>
                        <th>{t("Tx")}</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center", fontWeight: "420" }}>
                      {lpEvents &&
                        lpEvents.value?.map((e, idx) => (
                          <tr key={idx}>
                            <td>
                              <Address address={new URL(e.lp).pathname.split("/")[3]} placement="top" />
                            </td>
                            <td>{t(e.event_type.toUpperCase())}</td>
                            <td>${compactNumber(e.amount)}</td>
                            <td>{moment(e.event.tx.timestamp).format("ll")}</td>
                            <td>
                              <a target="_blank" rel="noopener noreferrer" href={chain.scan + "tx/" + e.event.tx.hash}>
                                {t("Tx")} <i className="uil uil-external-link-alt" />
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Tab>
              )}
              {etkOwners && etkOwners.value?.length > 0 && (
                <Tab eventKey="owners" title={t("Owners")} className="tableFixHead">
                  <Table striped className="whitespace-nowrap with-bkg table-rounded">
                    <thead>
                      <tr>
                        <th>{t("Owner")}</th>
                        <th>{t("Quantity")}</th>
                        <th>{t("Percentage")}</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center", fontWeight: "420" }}>
                      {etkOwners &&
                        etkOwners.value?.map((o, idx) => (
                          <tr key={idx}>
                            <td>{o.lp === "Others" ? o.lp : <Address address={o.lp} placement="top" />}</td>
                            <td>${compactNumber(o.amount)}</td>
                            <td>{o.percentage.toFixed(2)}%</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Tab>
              )}
            </Tabs>
          </Container>
          <br />
        </Card>
      )}
    </>
  );
};

LPActivity.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const etk = selectCurrent(state.ETokensReducer);
  const etkAddress = etk.address;

  const [lpEvents, etkOwners] = selectAPICallMultiple(state.APIReducer, makeAPICalls(etkAddress));

  return { etkAddress, lpEvents, etkOwners };
};

export default connect(mapStateToProps)(withTranslation()(LPActivity));
