import PropTypes from "prop-types";
import { Container, Tab, Tabs } from "react-bootstrap";
/* Components */
import RiskTabContent from "./RiskTabContent";
/* Helpers */
import { checkVisibility } from "../../../helpers/ensuro_helper";
/* Selectors */
import { selectAPICall } from "@ensuro/api-calls-store/src/store/api/selectors";
import { selectCurrent } from "../../../store/premiumsAccounts/selectors";
//i18n
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const RisksTabs = ({ pa, risks, mode, state, activeTab, selectTab }) => {
  const getActivePolicies = function (rm) {
    return selectAPICall(state.APIReducer, "activePolicies", [rm.address]) || -1;
  };
  const sortedRisks = risks?.value?.sort((r1, r2) => getActivePolicies(r2) - getActivePolicies(r1));

  return (
    <>
      {sortedRisks?.length > 0 && (
        <Container id="tabs">
          <Tabs className="mb-3 tab-font activity-tabs" activeKey={activeTab} onSelect={(k) => selectTab(k)}>
            {sortedRisks?.length > 0 &&
              sortedRisks.map(
                (rm, idx) =>
                  checkVisibility(rm, mode) && (
                    <Tab eventKey={rm.address} title={rm.name} key={idx}>
                      <RiskTabContent rm={rm} id={rm.name} />
                    </Tab>
                  )
              )}
          </Tabs>
        </Container>
      )}
    </>
  );
};

RisksTabs.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const pa = selectCurrent(state.PAReducer);
  return { pa, state };
};

export default connect(mapStateToProps)(withTranslation()(RisksTabs));
