import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
/* Components */
import { Modal, Form, InputGroup } from "react-bootstrap";
import WrongNetworkAlert from "./WrongNetworkAlert";
import TxButton from "../CommonForBoth/TxButton";
/* Helpers */
import { strToDec, strToDecZ, strToBN } from "../../helpers/number_format_helper";
import { isTransactionValid } from "../../helpers/store_helper";
/* Store */
import { selectEthCallMultiple, selectLastTransact } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { addRemoveEthSub } from "@ensuro/ethereum-store/src/utils/helpers/store_helper";
import { selectCurrent } from "../../store/eTokens/selectors";
import { selectChainId } from "../../store/user/selectors";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { chain, currency } from "../../config";
import "./style.scss";

const { ethers } = require("ethers");

const makeEthCalls = function (etkAddress) {
  return [{ address: etkAddress, abi: "EToken", method: "totalWithdrawable", args: [] }];
};

const WithdrawModal = ({
  t,
  user,
  lastTransaction,
  show,
  onHide,
  balance,
  poolAddress,
  etkAddress,
  chainId,
  totalWithrawable,
}) => {
  let dispatch = useDispatch();
  const [userAmount, setUserAmount] = useState("");
  const ready = balance.state === "LOADED" && chainId === chain.id;

  const invalidBalance = () => {
    return (
      !balance.value ||
      (userAmount !== ethers.MaxUint256 && strToDec(userAmount) && strToDec(userAmount).gt(balance.value))
    );
  };

  const exceedsTotalWithdrawable = () => {
    return (
      userAmount !== ethers.MaxUint256 &&
      totalWithrawable &&
      totalWithrawable.value &&
      strToDec(userAmount) &&
      strToDec(userAmount).gt(totalWithrawable.value) // The user is trying to withdraw more than available
    );
  };

  const dispatchWithdraw = () => {
    if (!isTransactionValid(lastTransaction)) return;
    dispatch({
      type: "ETH_TRANSACT",
      address: poolAddress,
      abi: "PolicyPool",
      method: "withdraw",
      args: [etkAddress, userAmount === ethers.MaxUint256 ? ethers.MaxUint256 : strToBN(userAmount)],
    });
  };

  useEffect(() => {
    return addRemoveEthSub(dispatch, "withdrawModal", makeEthCalls(etkAddress));
  }, [dispatch, etkAddress]);

  const getMin = () => {
    if (balance && balance.value && totalWithrawable && totalWithrawable.value) {
      return Math.min(balance.value.toString(), totalWithrawable.value.toString());
    }
    return 0;
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Withdraw ", { currency })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {chainId !== chain.id && <WrongNetworkAlert />}
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{t("Amount")}</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="0.00"
                id="withdraw-amount"
                isInvalid={
                  userAmount !== "" && (!strToDecZ(userAmount) || invalidBalance() || exceedsTotalWithdrawable())
                }
                value={userAmount === ethers.MaxUint256 ? getMin() : userAmount}
                onChange={(e) => setUserAmount(e.target.value || "")}
              />
              <InputGroup.Text>{currency.name}</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {!strToDecZ(userAmount) && t("Please provide a valid amount.")}
                {invalidBalance() ? (
                  <span>{t("Your balance is not enough.")}</span>
                ) : (
                  exceedsTotalWithdrawable() && (
                    <span>{t("The maximum you can withdraw is ", { min: getMin(), currency })}</span>
                  )
                )}
              </Form.Control.Feedback>
            </InputGroup>
            <p className="align-end">
              {t("Your balance")}: {balance && balance.value ? balance.value.toString() : ""}
              <strong onClick={() => setUserAmount(ethers.MaxUint256)} className="btn-text">
                {t("MAX")}
              </strong>
            </p>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <em style={{ color: "red" }}>{lastTransaction ? lastTransaction.error : ""}</em>
        <TxButton
          chainId={chainId}
          disabled={
            !ready ||
            !strToDecZ(userAmount) ||
            invalidBalance() ||
            exceedsTotalWithdrawable() ||
            (user && !user.canTransact)
          }
          onClick={dispatchWithdraw}
          text={t("Withdraw")}
        />
      </Modal.Footer>
      {user && !user.canTransact && (
        <p className="read-only">{t("Read-only mode. Connect to a wallet to perform transactions.")}</p>
      )}
    </Modal>
  );
};

WithdrawModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const user = state.UserReducer;
  const etk = selectCurrent(state.ETokensReducer);
  const etkAddress = etk.address;
  const url = new URL(etk.pool);
  const poolAddress = url.pathname.split("/")[3];
  const lastTransaction = selectLastTransact(state.EthereumReducer);
  const chainId = selectChainId(state.UserReducer);
  const [totalWithrawable] = selectEthCallMultiple(state.EthereumReducer, makeEthCalls(etkAddress));

  return { user, lastTransaction, etkAddress, poolAddress, chainId, totalWithrawable };
};

export default connect(mapStateToProps)(withTranslation()(WithdrawModal));
