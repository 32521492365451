// User Redux States
import { SET_USER, SET_PROVIDER, SET_CHAIN_ID, STORE_USER_KYC } from "./actionTypes";

const INIT_STATE = {
  address: null,
  loadingState: "NOT_LOADED",
  // NOT_LOADED -> Loading -> LOADED
  //                       \-> ERROR
  error: {
    message: "",
  },
  chainId: null,
  providerId: null,
  canTransact: false,
  signs: {},
};

function modifyNode(state, path, newValueFn) {
  if (path.length === 1) return { ...state, [path[0]]: newValueFn(state[path[0]] || {}) };
  else {
    return { ...state, [path[0]]: modifyNode(state[path[0]] || {}, path.slice(1, path.length), newValueFn) };
  }
}

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      state = { ...state, address: action.payload, canTransact: action.canTransact };
      break;

    case SET_CHAIN_ID:
      state = { ...state, chainId: Number(action.payload) };
      break;

    case SET_PROVIDER:
      state = { ...state, providerId: action.payload };
      break;

    case STORE_USER_KYC:
      const key = `${action.key}_${action.userAddress}`;
      const dict = { userAddress: action.userAddress, args: action.args };
      state = modifyNode(state, ["signs", key], () => dict);
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserReducer;
