import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
//API Calls
import APIReducer from "@ensuro/api-calls-store/src/store/api/reducer";
//eTokens
import ETokensReducer from "./eTokens/reducer";
//risks
import RisksReducer from "./risks/reducer";
//premiums account
import PAReducer from "./premiumsAccounts/reducer";
//user
import UserReducer from "./user/reducer";
//settings
import SettingsReducer from "./settings/reducer";
//Ethereum
import EthereumReducer from "@ensuro/ethereum-store/src/store/ethereum/reducer";

// Hack to restore to the empty state dispatching action "RESET_ALL"
// only for tests
let _wrapReducer;

if (process.env.NODE_ENV === "test") {
  _wrapReducer = (reducer) => {
    function resetReducer(state, action) {
      if (action.type === "RESET_ALL") return {};
      return reducer(state, action);
    }
    return resetReducer;
  };
} else {
  _wrapReducer = (reducer) => {
    return reducer;
  };
}

const rootReducer = _wrapReducer(
  combineReducers({
    // public
    Layout,
    EthereumReducer,
    ETokensReducer,
    RisksReducer,
    PAReducer,
    UserReducer,
    SettingsReducer,
    APIReducer,
  })
);

export default rootReducer;
