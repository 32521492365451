import React from "react";
import PropTypes from "prop-types";
/* Components */
import WrongNetworkAlert from "./WrongNetworkAlert";
import TxButton from "../CommonForBoth/TxButton";
import { Modal, Alert } from "react-bootstrap";
/* Helpers and Selectors */
import { selectLastTransact } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { isTransactionValid } from "../../helpers/store_helper";
import { selectChainId } from "../../store/user/selectors";
/* i18n and Config  */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { chain, faucet } from "../../config";
import "./style.scss";

const MintModal = ({ t, user, lastTransaction, show, onHide, chainId }) => {
  let dispatch = useDispatch();

  const dispatchMint = () => {
    if (!isTransactionValid(lastTransaction)) return;
    dispatch({ type: "ETH_TRANSACT", address: faucet.address, abi: "FaucetUSDC", method: "tap", args: [] });
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Mint USDC")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {chainId !== chain.id && <WrongNetworkAlert />}
        <Alert variant="primary">{t("You can mint 1000 USDC per day")}</Alert>
      </Modal.Body>
      <Modal.Footer>
        <em style={{ color: "red" }}>{lastTransaction ? lastTransaction.error : ""}</em>
        <TxButton
          chainId={chainId}
          onClick={dispatchMint}
          disabled={chainId !== chain.id || (user && !user.canTransact)}
          text={t("Mint")}
        />
      </Modal.Footer>
      {user && !user.canTransact && (
        <p className="read-only">{t("Read-only mode. Connect to a wallet to perform transactions.")}</p>
      )}
    </Modal>
  );
};

MintModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  const user = state.UserReducer;
  const lastTransaction = selectLastTransact(state.EthereumReducer);
  const chainId = selectChainId(state.UserReducer);

  return { user, lastTransaction, chainId };
};

export default connect(mapStateToProps)(withTranslation()(MintModal));
