export function addRemoveSub(dispatch, key, apiCalls, ethCalls, apiClock, ethClock) {
  dispatch({ type: "ETH_ADD_SUBSCRIPTION", key: key, componentEthCalls: ethCalls, clockCount: ethClock });
  dispatch({ type: "API_ADD_SUBSCRIPTION", key: key, componentApiCalls: apiCalls, clockCount: apiClock });
  return () => {
    dispatch({ type: "ETH_REMOVE_SUBSCRIPTION", key: key });
    dispatch({ type: "API_REMOVE_SUBSCRIPTION", key: key });
  };
}

export function isTransactionValid(lastTransaction) {
  return !lastTransaction || !(lastTransaction && !lastTransaction.state && lastTransaction.state !== "QUEUED");
}
